import { useSelector } from "react-redux";

import TEXT from "../../config/text";
import useTranslation from "../../hooks/useTranslation";
import telegramImg from "../../pages/activationPage/telegram.png";
import emailImg from "./gmail.png";

import style from "./style/contact.module.scss";

export default function ContactUsInfo() {
  const translation = useTranslation();

  const { webSettings, appInfo } = useSelector((state) => state.globalState);

  const { location, email, phone } = webSettings?.pages?.contact_us;

  const handleText = (text) => {
    if (translation?.pages?.contact_us?.[text]) {
      return translation?.pages?.contact_us?.[text];
    }

    return TEXT?.pages?.contact_us?.[text];
  };

  return (
    <div className={style["contact-us_info"]}>
      <div className={style["info"]}>
        <h1 className={style["title"]}>{handleText("title")}</h1>
      </div>
      <ul className={style["contact-list"]}>
        {location?.enabled && appInfo?.location_name && (
          <a
            href="https://ggplayer.app"
            target="_blank"
            rel="noreferrer"
            style={{ marginBottom: "2rem" }}
          >
            <img
              src={location?.icon}
              alt="location"
              className={style["icon"]}
            />
            <div className={style["list-info"]}>
              <h3>{handleText("location_title")}</h3>
            </div>
          </a>
        )}

        {phone?.enabled && appInfo?.phone && (
          <a
            href={"https://t.me/"}
            target="_blank"
            style={{ marginBottom: "2rem" }}
          >
            <img
              src={telegramImg}
              alt="phone numbeer"
              className={style["icon"]}
            />
            <div className={style["list-info"]}>
              <h3>Telegram</h3>
            </div>
          </a>
        )}

        {email?.enabled && appInfo?.email && (
          <a href={`mailto:latriade3x1@gmail.com`} target="_blank">
            <img src={emailImg} alt="email" className={style["icon"]} />
            <div className={style["list-info"]}>
              <h3>{handleText("email_title")}</h3>
              <p>latriade3x1@gmail.com</p>
            </div>
          </a>
        )}
      </ul>
    </div>
  );
}
